import React from "react";
import PropTypes from "prop-types";
import Layout from "ps-components/Layout/Layout.jsx";
import errorPageStyle from "assets/jss/material-kit-pro-react/views/errorPageStyles.jsx";
import image from "assets/img/Thank.png";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import Home from "@material-ui/icons/Home";
const NotFoundPage = ({ classes }) => (
  <Layout small={true} noParallax={true}>
    <br></br>

    <div
      className={classes.pageHeader1}

    >
      <GridItem md={12} sm={12}>
        <center>
          <img src={image} alt="thank" className={classes.imagefix} />
        </center>
      </GridItem>

      <div>

        <GridItem md={12} sm={12}>
          <center>
            <h2 className={classes.datafix} >
              Thank you for showing your interest
            </h2>
            <a href="/">      <h4 >  <Home className={classes.icon} style={{ marginBottom: `-3px` }} />     Go Back to Homepage</h4></a>
          </center>
        </GridItem>
      </div>
      {/* </div> */}

    </div>

  </Layout >
);
NotFoundPage.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(errorPageStyle)(NotFoundPage);
