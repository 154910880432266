import {
  title,
  whiteColor,


} from "assets/jss/material-kit-pro-react.jsx";
import image1 from "assets/img/lap.png";
import image2 from "assets/img/mobile.png";

const errorPageStyles = theme => ({
  contentCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "3",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    color: whiteColor,
    padding: "0 15px",
    width: "100%",
    maxWidth: "880px"
  },
  imagefix: {
    marginTop: "50px !important",
    [theme.breakpoints.down("sm")]: {
      width: "70% !important",
      height: "auto !important",

      marginTop: "0px !important"
    },

  },
  datafix: {
    fontSize: "2.25rem !important",
    fontWeight: `400`,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.00rem !important"
    },

  },
  postionfix: {
    width: "100%",
    top: `35%`,
    left: "0%",
    textAlign:"center !important",
    [theme.breakpoints.up("md")]: {
      width: `50%`,
      left: `28%`,
      top: `43%`,
      transform: `translate(-50%,-50%)`,
      textAlign:"left !important"
    },
  },
  marginfix: {
    marginLeft: "160px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      fontSize: "15px"
    },

  },
  title: {
    ...title,
    fontSize: "13.7em",
    color: whiteColor,
    letterSpacing: "14px",
    fontWeight: "700"
  },
  subTitle: {
    fontSize: "2.25rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.00rem",

      marginLeft: "0px !important",

    },
    marginTop: "0",
    marginBottom: "8px"
  },
  subTitle1: {
    fontSize: "2.25rem",
    fontWeight: `400`,
    textAlign: "center",
    marginLeft: "613px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.00rem",

      marginLeft: "0px !important"

    },
    "@media (max-width: 450px)": {
      marginTop: "50px !important",
      marginRight: "-110px !important",
      marginLeft: "52px !important"
    },
    marginTop: "-49px",
    marginBottom: "8px",

  },
  h4fix: {
    textAlign: "center",
    marginLeft: "589px",
    "@media (max-width: 450px)": {

      marginRight: "-107px !important",
      marginLeft: "49px !important"
    },
  },
  description: {
    fontSize: "1.125rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.00rem",
    },
    marginTop: "0",
    marginBottom: "8px"
  },
  pageHeader: {
    background: `url(` + image1 + `) no-repeat !important `,
    [theme.breakpoints.down("sm")]: {
      background: `url(` + image2 + `) no-repeat  !important`,
      backgroundSize: "100% !important",
      backgroundColor: "#03060e !important"
    },
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",

    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    }
  },
  pageHeader1: {

    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "65% ",
      minHeight: "73vh",
    },
    "@media (max-width: 450px)": {
      backgroundSize: "65% ",
      minHeight: "50vh",
    },


  },

  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block",
    "&,& *,& *:hover,& *:focus": {
      color: "inherit !important"
    }
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
    "&,& *,& *:hover,& *:focus": {
      color: "inherit !important"
    }
  },
  icon: {

    top: "3px",
    position: "relative",
    marginRight: "5px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
      width: "18px !important",
      height: "21px !important"
    },
  }
});

export default errorPageStyles;
